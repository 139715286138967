import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { createStore, useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import clearAction from "./clearAction";

import "./loginPage.css";
import { ResetTvRounded } from "@mui/icons-material";

createStore({});

async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });
  clearTimeout(id);

  return response;
}

export default function Login(props) {
  let navigate = useNavigate();
  const [loginStep, setLoginStep] = useState(0);
  const [loginError, setLoginError] = useState("");
  const date = new Date();
  const time = date.getHours();
  const [greetingMessage, setGreetingMessage] = useState("");
  const { register, handleSubmit, setValue } = useForm();
  const sp = `${process.env.REACT_APP_LOCAL_SERVER}`;

  const { actions } = useStateMachine({ updateAction, clearAction });

  useEffect(() => {

    actions.clearAction();

    if (time >= 0 && time < 12) {
      setGreetingMessage("Good Morning");
    } else if (time >= 12 && time < 18) {
      setGreetingMessage("Good Afternoon");
    } else {
      setGreetingMessage("Good Evening");
    }
  }, [time]);

  const onSubmitEmail = async (data) => {
    //console.log(data);
    const requestOptions = {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ action: "email", email: data.email, dob: data.dob, require_dob: process.env.REACT_APP_DOB_LOGIN }),
      timeout: 6000
    };

    if (process.env.REACT_APP_MODE === "demo") {
      // skip the login and set the patient to 1 and practice to 1
      //actions.updateAction({login_code: 'DEMO123', patient: , practice: , booking: {}});
      navigate(`/home`);

      return;
    }

    try {
      fetchWithTimeout(sp + "/login.php", requestOptions)
        .then(async (response) => {
          const loginResult = await response.json();
          //console.log(loginResult);
          if (loginResult.result === "Success") {
            setLoginError("");
            setLoginStep(1);
            setValue("patient_id", loginResult.detail.patient_id);
          } else if (loginResult.result === "NoPatient") {
            setLoginError(loginResult.detail);
          } else if (loginResult.result === "Error") {
            setLoginError(loginResult.detail);
          }
        })
        .catch((error) => {
          //setPageError(true);
          //console.error('There was an error!', error);
          setLoginError("Unable to contact the practice server, please try again later.");
        });
    } catch (error) {
      // Timeouts if the request takes
      // longer than 6 seconds
      console.log(error.name === 'AbortError');
      setLoginError("Unable to contact the practice server, please try again later.");
    }
  };

  const onSubmitCode = async (data) => {
    //console.log(data);

    const requestOptions = {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ action: "code", patient_id: data.patient_id, code: data.code }),
    };
    fetch(sp + "/login.php", requestOptions)
      .then(async (response) => {
        const loginResult = await response.json();

        //console.log(loginResult);

        if (loginResult.result === "Success") {
          actions.updateAction({ login_code: data.code, patient: loginResult.detail, practice: loginResult.practice, booking: {} });
          navigate(`/home`);
        } else {
          actions.clearAction();
          setLoginError(loginResult.detail);
          setLoginStep(1);
        }
      })
      .catch((error) => {
        //setPageError(true);
        //console.error('There was an error!', error);
      });
  };

  function newPatient() {
    actions.updateAction({ login_code: "_new", patient: {}, practice: {}, booking: {} });
    navigate("/register");
  }

  return (
    <>
      {window.innerWidth >= 670 ? (
        <div>
          {loginStep === 0 && (
            <div className="signup">
              <div className="signup-leftside">
                <h2>{greetingMessage}</h2>
                <hr></hr>
                <h4 style={{ color: "white" }}>Welcome to {process.env.REACT_APP_PRACTICE}</h4>
                {process.env.REACT_APP_PRACTICE_LOGO &&
                  <img style={{ maxWidth: `${process.env.REACT_APP_PRACTICE_LOGO_WIDTH}px` }} src={process.env.REACT_APP_PRACTICE_LOGO} />
                }
              </div>
              <div className="signin-rightside">
                <form onSubmit={handleSubmit(onSubmitEmail)}>
                  <h2>Existing patients...</h2>
                  <p className="sign-in-help-text">Sign in using the email address you gave to the practice{process.env.REACT_APP_DOB_LOGIN === 'true' && <> and your DOB</>}.</p>
                  <TextBoxComponent cssClass="e-outline" type="email" placeholder="Email Address" {...register("email", { required: true })} />
                  {process.env.REACT_APP_DOB_LOGIN === 'true' &&
                    <>
                      <br />
                      <MaskedTextBoxComponent cssClass="e-outline" placeholder="dd/mm/yyyy" mask='00/00/0000' {...register("dob", { required: true })} />
                    </>}
                  {loginError.length > 0 && <div className="errorMsg">{loginError}</div>}
                  <button className="login-signin-btn" type="submit">
                    Log in
                  </button>
                </form>
                <hr/>
                <h2>New patients...</h2>
                <div>Are you a new patient to the practice?</div>
                <button className="login-signin-btn" type="button" onClick={newPatient}>
                  Sign up
                </button>
              </div>
            </div>
          )}
          {loginStep === 1 && (
            <div className="signup">
              <div className="signup-leftside">
                <h2>{greetingMessage}</h2>
                <hr></hr>
                <h4 style={{ color: "white" }}>Welcome to {process.env.REACT_APP_PRACTICE}</h4>
                {process.env.REACT_APP_PRACTICE_LOGO &&
                  <img style={{ maxWidth: `${process.env.REACT_APP_PRACTICE_LOGO_WIDTH}px` }} src={process.env.REACT_APP_PRACTICE_LOGO} />
                }
              </div>
              <div className="signin-rightside">
                <form onSubmit={handleSubmit(onSubmitCode)}>
                  <h2>Enter Code</h2>
                  <hr></hr>
                  <p className="sign-in-help-text">Please enter the code we have emailed you. You may need to check your spam folder.</p>
                  <TextBoxComponent cssClass="e-outline" placeholder="Emailed Code" {...register("code", { required: true })} />
                  <div>{loginError}</div>
                  <button className="login-signin-btn" type="submit">
                    Confirm
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          {loginStep === 0 && (
            <div className="mobile-login-form">
              <div className="mobile-login-background"></div>

              <form className="mobile-login-content" onSubmit={handleSubmit(onSubmitEmail)}>
                <div className="mobile-login-header">
                  <h1 style={{ color: "white", borderBottom: "2px solid #fff" }}>Sign In</h1>
                </div>

                <div className="mobile-login-inputs">
                  <p className="mobile-login-help-text">
                    Welcome to ..... {process.env.REACT_APP_PRACTICE}<br />
                    <br />
                    Sign in using the email address you gave to the practice
                  </p>
                  <TextBoxComponent cssClass="e-outline" type="email" autoCapitalize="off" placeholder="Email Address" {...register("email", { required: true })} />
                  {process.env.REACT_APP_DOB_LOGIN === 'true' &&
                    <>
                      <br />
                      <MaskedTextBoxComponent cssClass="e-outline" placeholder="DOB: dd/mm/yyyy" mask='DOB: 00/00/0000' {...register("dob", { required: true })} />
                    </>}
                  <p className="mobile-login-help-text">{loginError}</p>
                </div>
                <button className="mobile-login-signin-button" type="submit">
                  Sign in
                </button>
              </form>

              <footer className="mobile-login-footer">
                <p className="mobile-login-help-text" style={{ color: "black" }}>
                  Are you a new patient to the practice?
                </p>
                <button className="mobile-login-signup-button" type="button" onClick={newPatient}>
                  Sign up!
                </button>
              </footer>
              <div className="mobile-login-dentalplus">
                <a className="dentalplus-website" href="http://www.dentalplus.co.uk" target="_blank" rel="noopener noreferrer">
                  Powered by DentalPlus
                </a>
              </div>
            </div>
          )}
          {loginStep === 1 && (
            <div className="mobile-login-form">
              <div className="mobile-login-background"></div>

              <form className="mobile-login-content" onSubmit={handleSubmit(onSubmitCode)}>
                <div className="mobile-login-header">
                  <h1 style={{ color: "white", borderBottom: "2px solid #fff" }}>Enter Code</h1>
                </div>

                <div className="mobile-login-inputs">
                  <p className="mobile-login-help-text">
                    Please enter the code we have emailed you. <br />
                    <br />
                    You may need to check your spam folder.
                  </p>
                  <TextBoxComponent cssClass="e-outline" placeholder="Emailed Code" autoCapitalize="off" {...register("code", { required: true })} />
                  <p className="mobile-login-help-text">{loginError}</p>
                </div>
                <button className="mobile-login-signin-button" type="submit">
                  Confirm
                </button>
              </form>

              <footer className="mobile-login-footer">
                <p className="mobile-login-help-text" style={{ color: "black" }}>
                  Are you a new patient to the practice?
                </p>
                <button className="mobile-login-signup-button" type="button" onClick={newPatient}>
                  Sign up!
                </button>
                <div className="mobile-login-footer-spacer"></div>
                <a className="dentalplus-website" href="http://www.dentalplus.co.uk" target="_blank" rel="noopener noreferrer">
                  Powered by DentalPlus
                </a>
              </footer>
            </div>
          )}
        </div>
      )}
    </>
  );
}
