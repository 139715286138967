import { useEffect, useState, useMemo, useRef } from 'react';

import { useNavigate } from "react-router-dom";
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import updateAction from "../updateAction";
import dayjs from 'dayjs';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BookDate(props) {
    let navigate = useNavigate();
    const { handleSubmit, setValue, getValues, watch, reset } = useForm();
    const sp = `${process.env.REACT_APP_LOCAL_SERVER}`;
    //const blankSlots = useMemo(() => { return { counts: [], day0: { slot: [] }, day1: { slot: [] }, day2: { slot: [] }, day3: { slot: [] }, day4: { slot: [] }, day5: { slot: [] }, day6: { slot: [] } } }, []);
    const blankSlots = useMemo(() => { return { counts: [], slots: {} } }, []);
    const [availList, setAvailList] = useState({ day0: { slot: [] }, day1: { slot: [] }, day2: { slot: [] }, day3: { slot: [] }, day4: { slot: [] }, day5: { slot: [] }, day6: { slot: [] } });
    const [dateList, setDateList] = useState({});
    const [maxRows, setMaxRows] = useState(0);
    const [searchDate, setSearchDate] = useState(dayjs().format('YYYYMMDD'));
    const [prevWeek, setPrevWeek] = useState();
    const [nextWeek, setNextWeek] = useState();
    const { actions, state } = useStateMachine({ updateAction });
    const timesEndRef = useRef(null)

    useEffect(() => {
        if (props.tabIndex < 1)
            reset({});
    }, [props.tabIndex])

    useEffect(() => {
        async function fetchData() {

            console.log(state);

            createSpinner({
                // Specify the target for the spinner to show
                target: document.getElementById('dateListContainer'),
            });

            showSpinner(document.getElementById('dateListContainer'));
            //setAvailList({});

            //console.log(sp + `/find_times.php?practice_id=${state.practice.practice_id}&reason=${state.booking.reason}&duration=${state.booking.duration}&resource=${state.clinician}&date=${searchDate}`);

            fetch(sp + `/find_times.php?practice_id=${state.booking.practice_id}&reason=${state.booking.reason}&duration=${state.booking.duration}&resource=${state.clinician}&date=${searchDate}`)
                .then(async response => {

                    const availListResult = await response.json();
                    if (availListResult.error) {
                        alert("Error: " + availListResult.error);
                        hideSpinner(document.getElementById('dateListContainer'));
                        return;
                    }

                    setPrevWeek(dayjs(availListResult.prev_week, 'MM/DD/YYYY').format('YYYYMMDD'));
                    setNextWeek(dayjs(availListResult.next_week, 'MM/DD/YYYY').format('YYYYMMDD'));

                    //console.log(availListResult);

                    setDateList(availListResult.counts);
                    setAvailList(availListResult.slots);

                    let _maxRows = 0;
                    for (let i = 0; i < 7; i++) {
                        if (availListResult.slots["day" + i]?.slot) {
                            if (availListResult.slots["day" + i].slot.length > _maxRows)
                                _maxRows = availListResult.slots["day" + i].slot.length;
                        }
                    }


                    setMaxRows(_maxRows);

                    hideSpinner(document.getElementById('dateListContainer'));

                })
                .catch(error => {
                    //setPageError(true);
                    //console.error('There was an error!', error);
                });

        }

        if (props.tabIndex === 3)
            fetchData();
    }, [props.tabIndex, blankSlots, searchDate, sp, state.booking.clinician, state.booking.duration, state.booking.reason]);

    const onSubmit = data => {
        if (getValues('selected_time')) {
            actions.updateAction(data);
            props.finishFnc(state.booking);
        } else {
            alert('You need to choose a time to continue');
        }
    };

    function chooseTime(data) {
        //console.log(data);

        setValue('selected_date', data.dbdate);
        setValue('selected_time', data.dbtime);
        setValue('display_time', data.starttime);
        setValue('clinician', data.resourceid);
        setValue('clinician_name', data.resource);
        setValue('duration', data.duration);

        setTimeout(() => {
            timesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, "200");


    }

    function chooseDate(date) {
        //console.log(nextWeek);
        //console.log(dayjs(nextWeek, 'YYYYMMDD'));

        if (date === 'prev')
            setSearchDate(prevWeek);
        else if (date === 'next')
            setSearchDate(nextWeek);
    }

    function goTo(page) {
        navigate(page);
    }

    function TimeTemplate(props) {
        //console.log(props);
        return (
            <div className="time-box" onClick={() => chooseTime(props.data)}>
                {props.data.starttime}
                <div className="time-with">{props.data.resource}</div>
            </div>
        )
    }

    return (
        <div>
            <form>
                <h4 className="booking-text">Choose an appointment time for your {state.booking.duration} minute {state.booking.reason_desc} appointment, then click continue</h4>
                <div className="dates-container">
                    <div className="dates-header">
                        <table width="100%" cellSpacing="0" cellPadding="0" className="dategrid-header">
                            <tbody>
                                <tr>
                                    <td width="33%">
                                        {/* {(prevWeek >= dayjs().format('YYYYMMDD')) && <button className="booking-change-week-btn" type="button" onClick={() => chooseDate('prev')}>Previous Week ({dayjs(prevWeek, 'YYYYMMDD').format('ddd Do MMM')} onwards)</button>} */}
                                        {(prevWeek >= dayjs().format('YYYYMMDD')) && <button className="booking-change-week-btn" type="button" onClick={() => chooseDate('prev')}><div className="booking-change-week-text"><ArrowBackIcon fontSize="large" style={{ marginRight: "10px" }} />Previous Week <br /> {dayjs(prevWeek, 'YYYYMMDD').format('ddd Do MMM')}</div></button>}
                                    </td>
                                    <td width="33%" style={{ textAlign: 'right' }}>
                                        <button className="booking-change-week-btn" type="button" onClick={() => chooseDate('next')}><div className="booking-change-week-text">
                                            Next Week <br /> {dayjs(nextWeek, 'YYYYMMDD').format('ddd Do MMM')}
                                            <ArrowForwardIcon fontSize="large" style={{ marginLeft: "10px" }} />
                                        </div></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {maxRows === 0 ?
                        <h4>There are no available appointments this week, please try the next week</h4>
                        :
                        <table className="dategrid-slots">
                            <thead>
                                <tr>
                                    {Object.entries(dateList).map(([key, value]) => (
                                        <th key={key} className="dategrid-th">{dayjs(value, 'YYYYMMDD').format('ddd Do MMM')}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Array(maxRows).fill(1).map((el, i) =>
                                    <tr key={i}>
                                        <td className="dategrid-td">{(availList?.day0?.slot && availList?.day0?.slot[i]) && <TimeTemplate data={availList.day0.slot[i]} />}</td>
                                        <td className="dategrid-td">{(availList?.day1?.slot && availList?.day1?.slot[i]) && <TimeTemplate data={availList.day1.slot[i]} />}</td>
                                        <td className="dategrid-td">{(availList?.day2?.slot && availList?.day2?.slot[i]) && <TimeTemplate data={availList.day2.slot[i]} />}</td>
                                        <td className="dategrid-td">{(availList?.day3?.slot && availList?.day3?.slot[i]) && <TimeTemplate data={availList.day3.slot[i]} />}</td>
                                        <td className="dategrid-td">{(availList?.day4?.slot && availList?.day4?.slot[i]) && <TimeTemplate data={availList.day4.slot[i]} />}</td>
                                        <td className="dategrid-td">{(availList?.day5?.slot && availList?.day5?.slot[i]) && <TimeTemplate data={availList.day5.slot[i]} />}</td>
                                        <td className="dategrid-td">{(availList?.day6?.slot && availList?.day6?.slot[i]) && <TimeTemplate data={availList.day6.slot[i]} />}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    }

                    {watch('display_time') &&
                        <div className="styled-div">
                            <h2 className="header">Your chosen appointment.</h2>
                            <p>{state.booking.reason_desc} on {dayjs(watch('selected_date'), 'YYYYMMDD').format('dddd Do MMMM')} @ {watch('display_time')} with {watch('clinician_name')} for {watch('duration')} minutes at {state.booking.practice_name}</p>
                        </div>
                    }
                    <div ref={timesEndRef}>
                        <div className="space-between-mobile">
                            <button className="booking-btns" type="button" onClick={() => handleSubmit(onSubmit)()} >{watch('display_time') ? 'Continue' : 'Choose an appointment time'}</button>
                            <button className="booking-btns" type="button" onClick={() => goTo(`/home`)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}