import { useEffect, useState, useRef } from 'react';

import { useNavigate } from "react-router-dom";
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import updateAction from "../updateAction";
import clearAction from "../clearAction";

export default function BookReason(props) {

    let navigate = useNavigate();
    const sp = `${process.env.REACT_APP_LOCAL_SERVER}`;
    const [reasonList, setReasonList] = useState([]);
    const [terms, setTerms] = useState('');
    const { handleSubmit, setValue, getValues, watch } = useForm();
    const { actions, state } = useStateMachine({ updateAction, clearAction });
    const reasonEndRef = useRef(null)

    useEffect(() => {
        async function fetchData() {

            //actions.clearAction();

            //actions.updateAction({ booking: {} });
            console.log(state);

            fetch(sp + `/config.php?action=reasons&request=${state.login_code}&practice_id=${state.booking.practice_id}`)
                .then(async response => {

                    const reasonListResult = await response.json();
                    setReasonList(reasonListResult.reasons);

                    if (reasonListResult.terms)
                        setTerms(reasonListResult.terms.data_value)

                })
                .catch(error => {
                    //setPageError(true);
                    //console.error('There was an error!', error);
                });

        }

        if (props.tabIndex === 1)
            fetchData();
    }, [props.tabIndex, actions, sp])

    function chooseReason(e) {
       //console.log(e);
        setValue('login_code', state.login_code);
        setValue('patient_id', state.patient.patient_id);
        setValue('practice_id', state.booking.practice_id);
        setValue('practice_name', state.booking.practice_name);
        setValue('reason', e.items[0].id);
        setValue('reason_desc', e.items[0].reason_desc);
        setValue('fee', e.items[0].fee);
        setValue('duration', e.items[0].default_duration);
        if (!e.items[0].fee)
            setValue('paid', true);
        else
            setValue('paid', false);
        //handleSubmit(onSubmit)();

        setTimeout(() => {
            reasonEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, "200");
    }

    const onSubmit = data => {
        if (getValues("reason")) {
            actions.updateAction({ booking: data });
            //console.log(state);
            props.finishFnc();
        } else {
            alert('You need to choose a reason to continue')
        }
    };

    const reasonItem = data => {
        //console.log(data);
        let desc = [];
        if (data.description)
            desc.push(data.description)
        if (data.fee)
            desc.push(`a payment of ${process.env.REACT_APP_CURRENCY}${data.fee / 100} is required for booking`);

        return (<div className="settings e-list-wrapper e-list-multi-line">
            <div className="reason-title">{data.reason_desc}</div>
            {desc &&
                <div className="reason-desc">{desc.join(' - ')}</div>
            }
        </div>);
    }

    function goTo(page) {
        navigate(page);
    }

    return (
        <div>
            <form>
                <h4 className="booking-text">What is the reason for your visit?</h4>
                <div className="reason-container">
                    <ListBoxComponent
                        dataSource={reasonList}
                        selectionSettings={{ mode: 'Single' }}
                        change={chooseReason}
                        itemTemplate={reasonItem}
                        cssClass="e-list-template"
                        fields={{ text: 'reason_desc', value: 'id' }}
                    />
                </div>
                {terms.length > 0 &&
                    <div style={{ padding: '10px' }} dangerouslySetInnerHTML={{ __html: terms }}></div>
                }
                {watch('reason') &&
                    <div className="styled-div">
                        <h2 className="header">Your chosen appointment.</h2>
                        <p>{watch('reason_desc')} for {watch('duration')} minutes at {state.booking.practice_name}</p>
                    </div>
                }
                <div className="space-between-mobile" ref={reasonEndRef}>
                    <button className="booking-btns" type="button" onClick={() => handleSubmit(onSubmit)()} >Continue</button>
                    <button className="booking-btns" type="button" onClick={() => goTo(`/home`)}>Cancel</button>
                </div>
            </form>

        </div>
    )
}